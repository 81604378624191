import * as classNames from 'classnames'
import * as React from 'react'
import {Button, PRIORITY} from 'wix-ui-tpa/Button'
import {TextButton} from 'wix-ui-tpa/TextButton'
import s from './event-link.st.css'
import {EventLinkProps} from '.'

export const EventLink: React.FC<EventLinkProps> = ({primary, secondary, mobile, ...rest}) => {
  if (primary) {
    return (
      <Button
        {...rest}
        className={classNames(s.primaryButton, {[s.mobile]: mobile})}
        priority={PRIORITY.basic}
        fullWidth={mobile}
      />
    )
  } else if (secondary) {
    return (
      <Button
        {...rest}
        className={classNames(s.secondaryButton, {[s.mobile]: mobile})}
        priority={PRIORITY.basicSecondary}
        fullWidth={mobile}
      />
    )
  }

  return <TextButton {...rest} className={classNames(s.link, {[s.mobile]: mobile})} />
}
